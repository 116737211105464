import React from 'react';
import Link from 'next/link';
import { FooterSite, HeaderSite } from '@acme/feature';
import { type NextPageContext } from 'next';
import * as Sentry from '@sentry/nextjs';

interface CustomErrorPageProps {
    statusCode: number | null;
}

const CustomErrorPage: React.FC<CustomErrorPageProps> = ({ statusCode }) => {
    const errorMessage = statusCode
        ? `An error ${statusCode} occurred on the server`
        : 'An error occurred on the client';

    return (
        <div className="flex flex-col min-h-screen bg-gradient-to-r from-blue-800 to-blue-500">
            {/* Header */}
            <HeaderSite />

            {/* Content */}
            <main className="container mx-auto px-4 py-16 text-white flex-grow flex flex-col justify-center items-center">
                <h2 className="text-4xl font-bold mb-6">
                    Oops! {statusCode ? `Error ${statusCode}` : 'Error'}
                </h2>
                <p className="text-xl mb-6">
                    {errorMessage}
                </p>

                <div className='flex flex-row gap-2'>
                    <Link href="/" className="bg-white px-6 py-2 rounded font-semibold text-blue-800 hover:bg-blue-200">
                        Go to Homepage
                    </Link>

                    <button className="bg-white px-6 py-2 rounded font-semibold text-blue-800 hover:bg-blue-200">
                        Contact Us
                    </button>
                </div>
            </main>

            {/* Footer */}
            <FooterSite />
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
CustomErrorPage.getInitialProps = async (contextData: NextPageContext) => {
    const { res, err } = contextData
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    await Sentry.captureUnderscoreErrorException(contextData);
    return { statusCode };
};

export default CustomErrorPage;